(function () {
	'use strict';
	angular
		.module('app')
		.controller('TokensCtrl', ['$scope', 'manageTokens', TokensCtrl]);

	function TokensCtrl($scope, manageTokens) {
		// getTokens();

		$scope.getTokens = getTokens;
		$scope.createToken = createToken;
		$scope.close = close;

		$scope.help = function (page, pagesf, fullURL, pageShare) {
			utilities.help(page, pagesf, fullURL, pageShare);
		};
		function getTokens() {
			manageTokens.getTokens(callback);
			function callback(tokens) {
				$scope.tokens.all = tokens;
			}
		}

		function createToken() {
			manageTokens.createToken(callback);
			function callback(encryptedToken) {
				console.log('token result:', encryptedToken);
				// $scope.tokens.all.push(encryptedToken);
			}
		}

		function close(e) {
			//Stop propagation so we don't close popovers behind the modal when clicking close
			if (e) {
				e.stopPropagation();
			}
			$scope.popover.config.show = false;
		}
	}
})();
